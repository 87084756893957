<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
            <v-overlay v-model="overlayDialog"></v-overlay>

            <div class="col-lg-12">

                <div class="card elevation-5">
                    <div class="card-body">

                        <div class="row">

                                <div class="col-lg-2 col-12">
                                    <v-switch v-model="newCustSwitchModel" label="New Customer" color="indigo" value="Y" hide-details @change="hideSearchCustomer()"></v-switch>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <v-autocomplete
                                        v-model="findCustomerModel"
                                        :items="findCustomerLists"
                                        item-value="CustomerId"
                                        item-text="NamaCustomer"
                                        :search-input.sync="search"
                                        cache-items
                                        flat
                                        hide-no-data
                                        hide-details
                                        clearable
                                        :disabled="showFindCustomerDisable"
                                        label="Find Customer" prepend-icon="mdi-account-multiple"
                                        @change="(event) => onChangeCustomer(event)"></v-autocomplete>
                                </div>
                                <div class="col-lg-4 col-12">
                                    <v-text-field v-model="customerIDModel" label="CUST. ID" clearable dense :disabled="customerIDDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-text-field v-model="customerNameModel" label="NAME" clearable dense :disabled="customerNameDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                <div class="col-lg-4 col-12">
                                    <v-text-field v-model="customerAddressModel" label="ADDRESS" clearable dense :disabled="customerAddressDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-text-field v-model="customerCityModel" label="CITY" clearable dense :disabled="customerCityDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-text-field v-model="customerProvinceModel" label="PROVINCE" clearable dense :disabled="customerProvinceDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-text-field v-model="customerPhoneModel" label="PHONE" @keypress="isNumber($event)" clearable dense :disabled="customerPhoneDisable" prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="brandModel" :items="brandLists" item-value="value" item-text="text" label="BRAND" @change="brandOnChange()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="categoryGroupModel" :items="categoryGroupLists" default="" item-value="cat_group" item-text="cat_group" label="CATEGORY GROUP" @change="(event) => categoryGroupOnChange(event)" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="categoryModel" :items="categoryLists" item-value="category_id" item-text="cat_desc" :label="categoryLabel" @change="categoryOnChange()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="machTypeModel" :items="machTypeLists" item-value="mach_type" :item-text="item => item.mach_type +' - '+ item.descr" :label="machTypeLabel" @change="machTypeOnChange()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="thicknessModel" :items="thicknessLists" item-value="Tebal" item-text="Tebal" :label="thicknessLabel" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="widthModel" :items="widthLists" item-value="matrl_width" item-text="matrl_width" :label="widthLabel" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="colorModel" :items="colorLists" item-value="KodeWarna" item-text="NamaWarna" :label="colorLabel" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="materialModel" :items="materialLists" item-value="Jenis" item-text="Jenis" :label="materialLabel" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="addIDModel" :items="addIDLists" item-value="add_id" item-text="descr" label="ADD ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="torIDModel" :items="torIDLists" item-value="tor_id" item-text="descr" label="TOR ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-4 col-12">
                                    
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-select v-model="prodIDModel" :items="prodIDLists" item-value="BarangJadiId" :item-text="item => item.BarangJadiId +' - '+ item.NamaBarang" :label="prodCodeText" :loading="loadingProdCode" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-text-field v-model="lengthModel" label="LENGTH" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-text-field v-model="quantityModel" label="QUANTITY" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                <div class="col-lg-2 col-12">
                                    <v-text-field v-model="priceModel" label="PRICE" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                                </div>
                                <!-- <div class="col-lg-4 col-12">
                                    <v-radio-group v-model="priceModeModel" dense row>
                                        <v-radio label="price By Length" value="M"></v-radio>
                                        <v-radio label="price By Quantity" value="U"></v-radio>
                                    </v-radio-group>
                                </div> -->
                                <div class="col-lg-4 text-right">
                                    <v-select v-model="addReasonModel" :items="addReasonLists" item-value="str1" item-text="str2" label="Reason" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                                </div>
                                <div class="col-lg-12 col-12">
                                    <v-textarea v-model="remarkModel" label="REMARK" rows="2" clearable dense prepend-icon="mdi-note-outline"></v-textarea>
                                </div>
                                <div class="col-lg-12 col-12 text-right">
                                    <v-btn class="mr-3 mt-3" color="red" elevation="2" small dense rounded @click="btnClearAll()" dark>CLEAR</v-btn>
                                    <v-btn class="mt-3" color="primary" elevation="2" small dense rounded @click="btnAddNewCancelOrder()">SUBMIT</v-btn>
                                </div>
                            </div>
                    
                    </div>
                </div>

            </div>


            <div class="col-lg-12 cardResult">

                <div class="card elevation-5">
                    <div class="card-body">
                            
                        <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" :loading="loadingDatatable" dense>
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <div class="d-flex w-25">
                                        <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    </div>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.length`]="{ item }">
                                {{item.length + ' m'}} 
                            </template>
                            <template v-slot:[`item.wgt`]="{ item }">
                                {{item.wgt + ' Kg'}} 
                            </template>
                            <template v-slot:[`item.subtotal`]="{ item }">
                                {{'IDR ' + item.subtotal}} 
                            </template>
                        </v-data-table>
                            
                    </div>
                </div>

            </div>

        </div>

        <v-dialog v-model="dialogNewCancelOrder" max-width="1280px">
            
    
        
        </v-dialog>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Customer Order',
                disabled: false,
                href: '#',
                },
                {
                text: 'Missed Order',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            disabled_region: false,
            officeModel: '',
            officeLists: [],
            disabled_office: false,
            salesModel: '',
            salesLists: [],
            disabled_salesman: false,
            reasonModel: '',
            reasonLists: [],
            dateStartModel: '',
            dateEndModel: '',
            dateStartModal: false,
            dateEndModal: false,
            itemLists: [],
            headers: [
                { text: 'DATE', value: 'dt_created', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'CUSTOMER', value: 'cust', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SALESMAN', value: 'NamaSales', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD. CODE', value: 'product_code', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'PROD. DESC', value: 'NamaBarang', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'length', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'qty', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'WGT.', value: 'wgt', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL', value: 'subtotal', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'REASON', value: 'str2', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'remark', align: 'right', class: 'primary--text blue lighten-5' },
                { text: '', value: 'action', align: 'center', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatable: false,
            searchItem: '',
            dialogNewCancelOrder: false,
            brandModel: '',
            brandLists:[
                {
                    value: 'KENCANA',
                    text: 'KENCANA'
                },
                {
                    value: 'ARYA',
                    text: 'ARYA'
                },
                {
                    value: 'SEMANGGI',
                    text: 'SEMANGGI'
                },
            ],
            categoryGroupModel: '',
            categoryGroupLists: [],
            categoryModel: '',
            categoryLists: [],
            machTypeModel: '',
            machTypeLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            lengthModel: '',
            colorModel: '',
            colorLists: [],
            materialModel: '',
            materialLists: [],
            addIDModel: '',
            addIDLists: [],
            torIDModel: '',
            torIDLists: [],
            addReasonModel: '',
            addReasonLists: [],
            quantityModel: '',
            priceModel: '',
            priceModeModel: 'M',
            prodIDModel: '',
            prodIDLists: [],
            categoryLabel: 'CATEGORY',
            machTypeLabel: 'TYPE',
            thicknessLabel: 'THICKNESS',
            widthLabel: 'WIDTH',
            colorLabel: 'COLOR',
            materialLabel: 'MATERIAL',
            prodCodeText: 'PRODUCT CODE',
            overlayDialog: false,
            loadingProdCode: false,
            findCustomerModel: '',
            findCustomerLists: [],
            showFindCustomerDisable: false,
            customerIDModel: '',
            customerIDDisable: true,
            customerNameModel: '',
            customerNameDisable: true,
            customerAddressModel: '',
            customerAddressDisable: true,
            customerCityModel: '',
            customerCityDisable: true,
            customerProvinceModel: '',
            customerProvinceDisable: true,
            search: null,
            newCustSwitchModel: '',
            customerPhoneModel: '',
            customerPhoneDisable: true,
            panelAddNew: [0],
            remarkModel: ''
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)

            this.dateStartModel = this.currentDate()
            this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesModel = res.data.sales[0].SalesId
                    this.salesLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.reasonLists = res.data.reason
                this.addReasonLists = res.data.reason
                this.categoryGroupLists = res.data.category
                this.categoryLists = res.data.category
                this.machTypeLists = res.data.machType
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.colorLists = res.data.color
                this.materialLists = res.data.material
                this.addIDLists = res.data.add_id
                this.torIDLists = res.data.tor_id

                this.getData()

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {
                
                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async getData(){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardResult").style.display = "block";
            this.loadingDatatable = true

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/getData`, { 

            },
            {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                this.loadingDatatable = false
                this.itemLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async btnAddNewCancelOrder(){

            if(this.customerNameModel == null || this.customerNameModel == ''){
                Swal.fire({
                        text: 'Please Insert Customer Name !',
                        icon: 'warning',
                    })
                return false
            }

            if(this.prodIDModel == null || this.prodIDModel == ''){
                Swal.fire({
                        text: 'Please Select Product Code !',
                        icon: 'warning',
                    })
                return false
            }

            if(this.lengthModel == null || this.lengthModel == ''){
                Swal.fire({
                        text: 'Please Insert Product Length !',
                        icon: 'warning',
                    })
                return false
            }

            if(this.quantityModel == null || this.quantityModel == ''){
                Swal.fire({
                        text: 'Please Insert Product Quantity !',
                        icon: 'warning',
                    })
                return false
            }

            if(this.priceModel == null || this.priceModel == ''){
                Swal.fire({
                        text: 'Please Insert Product Price !',
                        icon: 'warning',
                    })
                return false
            }
            
            if(this.addReasonModel == null || this.addReasonModel == ''){
                Swal.fire({
                        text: 'Please Select Reason !',
                        icon: 'warning',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/storeData`, { 
                    custID : this.customerIDModel ? this.customerIDModel : 'NEW',
                    custName : this.customerNameModel ? this.customerNameModel : '',
                    custAddress : this.customerAddressModel ? this.customerAddressModel : '',
                    custCity : this.customerCityModel ? this.customerCityModel : '',
                    custProvince : this.customerProvinceModel ? this.customerProvinceModel : '',
                    custPhone : this.customerPhoneModel ? this.customerPhoneModel : '',
                    addReason : this.addReasonModel ? this.addReasonModel : '',
                    product_code : this.prodIDModel ? this.prodIDModel : '',
                    product_length : this.lengthModel ? this.lengthModel : '',
                    product_qty : this.quantityModel ? this.quantityModel : '',
                    product_price : this.priceModel ? this.priceModel : '',
                    remark : this.remarkModel ? this.remarkModel : '', 
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                
                this.$store.dispatch('setOverlay', false)

                Swal.fire({
                    text: 'Data submitted successfully',
                    icon: 'success',
                })

                this.getData()

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async regionOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/regionOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.officeLists = res.data.office
                this.salesLists = res.data.sales

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async officeOnChange(id){

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/officeOnChange?type=${this.typeModel ? this.typeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&office=${id ? id : ''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.salesLists = res.data.sales

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },

        async addNewOrderCancel(){

            this.dialogNewCancelOrder = true
        },

        async brandOnChange(){

            this.overlayDialog = true

            this.categoryModel = ''
            this.categoryLists = []

            this.machTypeModel= ''
            this.machTypeLists = []

            this.thicknessModel = ''
            this.thicknessLists = []

            this.widthModel = ''
            this.widthLists = []

            this.lengthModel = ''

            this.colorModel = ''
            this.colorLists = []

            this.materialModel = ''
            this.materialLists = []

            this.addIDModel = ''
            this.torIDModel = ''
            this.quantityModel = ''

            this.prodIDModel = ''
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            this.priceModel = ''
            // this.pripriceModeModel = 'M'
            this.remarkItemModel = ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/brandOnChange?brand_id=${this.brandModel?this.brandModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.categoryLists = res.data.category
                this.categoryLabel = 'CATEGORY(' + res.data.category.length + ')'
                this.machTypeLists = res.data.machType
                this.machTypeLabel = 'TYPE(' + res.data.machType.length + ')'
                this.thicknessLists = res.data.thickness
                this.thicknessLabel = 'THICKNESS(' + res.data.thickness.length + ')'
                this.widthLists = res.data.width
                this.widthLabel = 'WIDTH(' + res.data.width.length + ')'
                this.colorLists = res.data.color
                this.colorLabel = 'COLOR(' + res.data.color.length + ')'
                this.materialLists = res.data.material
                this.materialLabel = 'MATERIAL(' + res.data.material.length + ')'
                this.prodIDLists = res.data.prod_code
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.prod_code.length + ' products)'

                this.overlayDialog = false
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        categoryGroupOnChange(id){

            this.overlayDialog = true

            if(id){

                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/getCategoryByGroup?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.overlayDialog = false
                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            } else {

                this.overlayDialog = true
                this.categoryLists = []

                axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/getCategoryByGroup?id=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.overlayDialog = false
                    this.categoryLists = res.data.result

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })

            }

        },

        async categoryOnChange(){

            this.overlayDialog = true

            this.machTypeModel= ''
            this.machTypeLists = []

            this.thicknessModel = ''
            this.thicknessLists = []

            this.widthModel = ''
            this.widthLists = []

            this.lengthModel = ''

            this.colorModel = ''
            this.colorLists = []

            this.materialModel = ''
            this.materialLists = []

            this.addIDModel = ''
            this.torIDModel = ''
            this.quantityModel = ''

            this.prodIDModel = ''
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            this.priceModel = ''
            // this.pripriceModeModel = 'M'
            this.remarkItemModel = ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/categoryOnChange?brand_id=${this.brandModel?this.brandModel:''}&category=${this.categoryModel?this.categoryModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.machTypeLists = res.data.machType
                this.machTypeLabel = 'TYPE(' + res.data.machType.length + ')'
                this.thicknessLists = res.data.thickness
                this.thicknessLabel = 'THICKNESS(' + res.data.thickness.length + ')'
                this.widthLists = res.data.width
                this.widthLabel = 'WIDTH(' + res.data.width.length + ')'
                this.colorLists = res.data.color
                this.colorLabel = 'COLOR(' + res.data.color.length + ')'
                this.materialLists = res.data.material
                this.materialLabel = 'MATERIAL(' + res.data.material.length + ')'
                this.prodIDLists = res.data.prod_code
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.prod_code.length + ' products)'

                this.overlayDialog = false
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async machTypeOnChange(){

            this.overlayDialog = true

            this.thicknessModel = ''
            this.thicknessLists = []

            this.widthModel = ''
            this.widthLists = []

            this.lengthModel = ''

            this.colorModel = ''
            this.colorLists = []

            this.materialModel = ''
            this.materialLists = []

            this.addIDModel = ''
            this.torIDModel = ''
            this.quantityModel = ''

            this.prodIDModel = ''
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            this.priceModel = ''
            // this.pripriceModeModel = 'M'
            this.remarkItemModel = ''

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/machTypeOnChange?brand_id=${this.brandModel?this.brandModel:''}&category=${this.categoryModel?this.categoryModel:''}&mach_type=${this.machTypeModel?this.machTypeModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.thicknessLists = res.data.thickness
                this.thicknessLabel = 'THICKNESS(' + res.data.thickness.length + ')'
                this.widthLists = res.data.width
                this.widthLabel = 'WIDTH(' + res.data.width.length + ')'
                this.colorLists = res.data.color
                this.colorLabel = 'COLOR(' + res.data.color.length + ')'
                this.materialLists = res.data.material
                this.materialLabel = 'MATERIAL(' + res.data.material.length + ')'
                this.prodIDLists = res.data.prod_code
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.prod_code.length + ' products)'

                this.overlayDialog = false
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        async populateProdCode(){
            
            this.loadingProdCode = true
            this.prodIDModel = ''
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/getProdCode?brand=${this.brandModel?this.brandModel:''}&category=${this.categoryModel?this.categoryModel:''}&machType=${this.machTypeModel?this.machTypeModel:''}&width=${this.widthModel?this.widthModel:''}&thickness=${this.thicknessModel?this.thicknessModel:''}&material=${this.materialModel?this.materialModel:''}&color=${this.colorModel?this.colorModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.loadingProdCode = false
                this.prodIDModel = ''
                this.prodIDLists = res.data.result
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.result.length + ' products)'
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401') {
                    this.$router.push('/');
                } else {
                    Swal.fire({
                        text: err.response.data.data,
                        icon: 'error',
                    })
                }

            })

        },

        isNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 46 || keyCode > 57 )) { // 46 is dot
                event.preventDefault()
            }
        },

        hideSearchCustomer(){

            if(this.newCustSwitchModel == 'Y'){
                this.findCustomerModel  = ''
                this.findCustomerLists = []
                this.showFindCustomerDisable = true
                this.customerNameModel = ''
                this.customerNameDisable = false
                this.customerAddressModel = ''
                this.customerAddressDisable = false
                this.customerCityModel = ''
                this.customerCityDisable = false
                this.customerProvinceModel = ''
                this.customerProvinceDisable = false
                this.customerPhoneModel = ''
                this.customerPhoneDisable = false
            } else {
                this.showFindCustomerDisable = false
                this.customerNameModel = ''
                this.customerNameDisable = true
                this.customerAddressModel = ''
                this.customerAddressDisable = true
                this.customerCityModel = ''
                this.customerCityDisable = true
                this.customerProvinceModel = ''
                this.customerProvinceDisable = true
                this.customerPhoneModel = ''
                this.customerPhoneDisable = true
            }

        },

        async getCustomer(id){

            await axios.post(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/getCustomer`, { 
                    id : id,
            },{
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {

                this.findCustomerLists = res.data.result

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })

        },

        async onChangeCustomer(id){
            
            if(id == null || id == '' ){

                this.customerIDModel = ''
                this.customerNameModel = ''
                this.customerAddressModel = ''
                this.customerCityModel = ''
                this.customerProvinceModel = ''
                this.customerPhoneModel = ''

            } else {

                this.$store.dispatch('setOverlay', true)

                await axios.get(`${process.env.VUE_APP_URL}/api/kmb/OrderCancel/getCustomerDetails?id=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.customerIDModel = res.data.result.CustomerId
                    this.customerNameModel = res.data.result.NamaCustomer
                    this.customerAddressModel = res.data.result.Alamat
                    this.customerCityModel = res.data.result.Kota
                    this.customerProvinceModel = res.data.result.Propinsi
                    this.customerPhoneModel = res.data.result.Telepon

                    this.$store.dispatch('setOverlay', false)
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }

        },

        btnClearAll(){

            this.newCustSwitchModel = 'N'
            this.findCustomerModel  = ''
            this.findCustomerLists = []
            this.customerIDModel = ''
            this.customerIDDisable = true
            this.customerNameModel = ''
            this.customerNameDisable = true
            this.customerAddressModel = ''
            this.customerAddressDisable = true
            this.customerCityModel = ''
            this.customerCityDisable = true
            this.customerProvinceModel = ''
            this.customerProvinceDisable = true
            this.customerPhoneModel = ''
            this.customerPhoneDisable = true
            this.brandModel = ''
            this.categoryGroupModel = ''
            this.categoryModel = ''
            this.machTypeModel = ''
            this.thicknessModel = ''
            this.widthModel = ''
            this.lengthModel = ''
            this.colorModel = ''
            this.materialModel = ''
            this.addIDModel = ''
            this.torIDModel = ''
            this.addReasonModel = ''
            this.quantityModel = ''
            this.priceModel = ''
            this.priceModeModel = 'M'
            this.prodIDModel = ''
            this.remarkModel = ''

        }

    },
    watch: {

        search (val) {
            val && val !== this.findCustomerModel && this.getCustomer(val)
        },
        
    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

    .modal {
        margin: 0 auto; 
    }

</style>